import { UntitledIcon } from '@faceup/icons'
import { ulDotsVertical } from '@faceup/icons/ulDotsVertical'
import { Button, Dropdown, type DropdownProps } from '@faceup/ui-base'

type Props = DropdownProps

const MoreOptions = (props: Props) => (
  <Dropdown placement='bottomRight' {...props}>
    <Button
      icon={<UntitledIcon icon={ulDotsVertical} />}
      type='text'
      data-onboarding='more-options-button'
    />
  </Dropdown>
)

export default MoreOptions
