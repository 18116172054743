import { useMutation, useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulKey01 } from '@faceup/icons/ulKey01'
import { ulLock01 } from '@faceup/icons/ulLock01'
import { CreateMemberModal, EditMemberModal, MotherProvider } from '@faceup/institution'
import { useSearchParams } from '@faceup/router'
import { Card, UserAvatar } from '@faceup/ui'
import {
  Button,
  Divider,
  Pagination,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  notification,
  useMessage,
  useModal,
} from '@faceup/ui-base'
import { AccountType, Institution, InvitationStatus } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { Fragment, useEffect, useState } from 'react'
import { useAdminAccessRights } from '../Contexts/AdminAccessRightsProvider'
import { graphql } from '../__generated__'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../constants'
import GrantAccessActionModal from './GrantAccessActionModal'
import MoreOptions from './MoreOptions'
import PermissionText from './PermissionText'
import { TransferOwnershipModal } from './TransferOwnershipModal'

type CompanyMembersQuery = ResultOf<typeof query.CompanyMembersQuery>
type CompanyMembersQuery_allCompanyByIdMembers_items_item = NonNullable<
  NonNullable<CompanyMembersQuery['allCompanyByIdMembers']>['items'][number]
>

const { Text } = Typography

const query = {
  CompanyMembersQuery: graphql(`
    query CompanyMembersQuery(
      $institutionId: UUID!
      $motherId: UUID!
      $page: Int!
      $rowsPerPage: Int!
    ) {
      institution(institutionId: $institutionId) {
        id
        organizationalUnitName
        config {
          id
          reportCategories {
            id
          }
        }
        descendents {
          id
          organizationalUnitName
        }

        ...CreateMemberModal_institution
        ...EditMemberModal_institution
      }
      allCompanyByIdMembers(
        companyId: $motherId
        page: $page
        rowsPerPage: $rowsPerPage
      ) {
        totalCount
        items {
          id
          name
          email
          phone
          profileImageUrl
          companyIds(motherId: $motherId)
          editable(motherId: $motherId)
          accountType(motherId: $motherId)
          invitation
          sso
          isPartner
          lastLogin
          mother(motherId: $motherId) {
            id
            config {
              id
              reportCategories {
                id
              }
            }
            descendents {
              id
            }
          }
          companies(motherId: $motherId) {
            id
            organizationalUnitName
          }
          keys {
            id
            twoFactorAuthentication
            permissions(motherId: $motherId) {
              id
              type
              enabled
              ...PermissionText_permission
            }
          }
          ...TransferOwnershipModal_member
          ...EditMemberModal_memberToEdit
          ...GrantAccessActionModal_user
        }
      }
    }
  `),
}

const mutations = {
  DeleteMember: graphql(`
    mutation DeleteMemberMutation($input: DeleteMemberInput!) {
      deleteMember(input: $input) {
        success
        deletedMemberId
      }
    }
  `),
  RemovePartnerUser: graphql(`
    mutation RemovePartnerUserMutation($input: RemovePartnerUserInput!) {
      removePartnerUser(input: $input) {
        success
        deletedMemberId
      }
    }
  `),
  ResendMemberInvitation: graphql(`
    mutation ResendMemberInvitationMutation($input: ResendMemberInvitationInput!) {
      resendMemberInvitation(input: $input) {
        member {
          id
          invitation
        }
      }
    }
  `),
}

type Props = {
  institutionId: string
}

const MembersTable = ({ institutionId }: Props) => {
  const {
    isAllowedInstitutionEdit,
    isAllowedInstitutionMemberActions,
    isAllowedBasicMemberActions,
  } = useAdminAccessRights()
  const pageSize = 10

  const [params] = useSearchParams()

  const [page, setPage] = useState(1)
  const [grantAccessVisible, setGrantAccessVisible] = useState(false)
  const [memberToEdit, setMemberToEdit] = useState<Member | null>(null)
  const [memberToGrantOwnerShip, setMemberToGrantOwnerShip] = useState<Member | null>(null)
  const [createMemberVisible, setCreateMemberVisible] = useState<boolean>(false)
  const message = useMessage()
  const modal = useModal()

  const { data, loading, refetch } = useQuery(query.CompanyMembersQuery, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    fetchPolicy: 'cache-and-network',
    variables: {
      institutionId: institutionId ?? '',
      motherId: institutionId,
      page: page - 1,
      rowsPerPage: pageSize,
    },
  })

  const [resendInvitation] = useMutation(mutations.ResendMemberInvitation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: () => {
      message.success('Resent')
    },
  })

  const [deleteMember] = useMutation(mutations.DeleteMember, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (!data.deleteMember?.success) {
        message.error('Unable to delete member')
      }
    },
  })

  const [removePartnerUser] = useMutation(mutations.RemovePartnerUser, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (!data.removePartnerUser?.success) {
        message.error('Unable to remove partner user')
      }
    },
  })

  const makeTableRow = (item: CompanyMembersQuery_allCompanyByIdMembers_items_item) => ({
    __typename: 'Member' as const,
    id: item.id,
    name: item.name ?? '',
    companyIds: item.companyIds,
    phone: item.phone ?? '',
    companies: item.companies,
    email: item.email ?? '',
    editable: item.editable,
    profileImageUrl: item.profileImageUrl ?? null,
    permissions: item.keys?.permissions ?? [],
    invitation: item.invitation,
    sso: Boolean(item.sso),
    lastLogin: item.lastLogin,
    mother: item.mother ?? null,
    accountType: item.accountType ?? null,
    security: {
      sso: Boolean(item.sso),
      twoFactorAuthentication: Boolean(item.keys?.twoFactorAuthentication),
    },
  })

  const institution = data?.institution
  const members = data?.allCompanyByIdMembers?.items ?? []
  type Member = (typeof members)[number]
  const allCompaniesCount = ((institution?.descendents ?? []).length ?? 0) + 1
  const companies = [institution, ...(institution?.descendents ?? [])]
  const totalCount = data?.allCompanyByIdMembers?.totalCount || 0
  const allCategoriesCount = (institution?.config?.reportCategories?.length ?? 0) + 1

  useEffect(() => {
    const action = params.get('action')
    if (action === 'grantAccess') {
      setGrantAccessVisible(true)
    }
  }, [params])

  return (
    <MotherProvider motherId={institutionId}>
      <Card>
        <Card.Header
          extra={
            <Button
              type='primary'
              disabled={!isAllowedInstitutionEdit}
              onClick={() => setCreateMemberVisible(true)}
            >
              Add member
            </Button>
          }
        >
          Members
        </Card.Header>
        <Table
          style={{ marginTop: 20 }}
          loading={loading}
          rowKey='id'
          dataSource={members.map(member => makeTableRow(member))}
          locale={{ emptyText: ' ' }}
          scroll={{ x: 'max-content' }}
          columns={[
            {
              title: 'Name',
              key: 'name',
              width: allCompaniesCount > 1 ? '25%' : '35%',
              render: member => (
                <Space align='center'>
                  <UserAvatar user={member} style={{ marginRight: 10 }} />
                  <Space direction='vertical' size={0}>
                    <Space>
                      <Text style={{ fontSize: 14 }}>{member.name || member.email}</Text>
                      {member.accountType === AccountType.Owner && <Tag color='blue'>Owner</Tag>}
                    </Space>
                    <Text type='secondary' style={{ fontSize: 13 }}>
                      {member.email} {member.phone && <>({member.phone})</>}
                    </Text>
                  </Space>
                </Space>
              ),
            },
            {
              visible: allCompaniesCount > 1,
              title: 'Institution',
              key: 'companies',
              filters: companies?.map(company => ({
                text: company?.organizationalUnitName ?? '',
                value: company?.id ?? '',
              })),
              // @ts-expect-error companies and member cannot inherit types
              onFilter: (value, member) => member.companyIds.includes(value),
              width: '20%',
              render: member => {
                const companies = member.companies
                if (member.companyIds.length === allCompaniesCount && allCompaniesCount > 1) {
                  return <Tag color='default'>All</Tag>
                }

                return (
                  <Space align='center'>
                    {companies
                      .slice(0, 1)
                      .map((company: { id: string; organizationalUnitName: string }) => (
                        <Tag color='default' key={company?.id ?? ''}>
                          {company?.organizationalUnitName}
                        </Tag>
                      ))}
                    {companies.length > 1 && <Tag color='default'>+{companies.length - 1}</Tag>}
                  </Space>
                )
              },
            },
            {
              title: 'Permissions',
              key: 'permissions',
              width: allCompaniesCount ? '32%' : '42%',
              render: member => (
                <Text>
                  {member.permissions
                    .filter(permission => permission?.enabled)
                    .sort((a, b) => (a?.type?.charCodeAt(0) ?? 0) - (b?.type?.charCodeAt(0) ?? 0))
                    .map(permission => (
                      <Fragment key={permission?.id ?? ''}>
                        <PermissionText
                          permission={permission}
                          key={permission?.id ?? ''}
                          allCategoriesCount={allCategoriesCount}
                        />
                        <Divider type='vertical' />
                      </Fragment>
                    ))}
                </Text>
              ),
            },
            {
              title: 'Security',
              width: '8%',
              key: 'security',
              render: ({ security }) => (
                <Space direction='vertical'>
                  {security.sso && (
                    <Space>
                      <UntitledIcon icon={ulKey01} />
                      SSO
                    </Space>
                  )}
                  {security.twoFactorAuthentication && (
                    <Space>
                      <UntitledIcon icon={ulLock01} />
                      2FA
                    </Space>
                  )}
                  {!security.sso && !security.twoFactorAuthentication && (
                    <Typography.Text type='secondary'>-</Typography.Text>
                  )}
                </Space>
              ),
            },
            {
              title: 'Status',
              key: 'invitation',
              width: '8%',
              render: ({ sso, invitation }) => (
                <>
                  {invitation === InvitationStatus.Active && <Tag color='#217F50'>Active</Tag>}
                  {invitation === InvitationStatus.Expired && <Tag color='#B60024'>Expired</Tag>}
                  {invitation === InvitationStatus.Sent && <Tag color='#E66B00'>Sent</Tag>}
                  {sso && <Tag color='gold'>SSO</Tag>}
                </>
              ),
            },
            {
              title: 'Last login',
              key: 'lastLogin',
              width: '10%',
              render: ({ lastLogin }) => (
                <Tooltip title={`${moment(lastLogin).format(DATE_TIME_FORMAT)}`}>
                  <span>{lastLogin ? moment(lastLogin).format(DATE_FORMAT) : ''}</span>
                </Tooltip>
              ),
            },
            {
              visible: isAllowedInstitutionMemberActions || isAllowedBasicMemberActions,
              title: '',
              key: 'id',
              width: '5%',
              render: ({ id }) => {
                const member = members?.find(m => m?.id === id) ?? null
                if (!member?.editable) {
                  return null
                }
                return (
                  <MoreOptions
                    menu={{
                      items: [
                        ...(member.invitation === InvitationStatus.Active
                          ? [
                              {
                                key: 'edit',
                                label: 'Edit',
                                onClick: () => setMemberToEdit(member),
                                disabled: !isAllowedInstitutionMemberActions,
                              },
                              ...(member.accountType !== AccountType.Owner
                                ? [
                                    {
                                      key: 'transfer-ownership',
                                      label: 'Transfer ownership',
                                      onClick: () => setMemberToGrantOwnerShip(member),
                                    },
                                  ]
                                : []),
                            ]
                          : [
                              {
                                key: 'resend',
                                label: 'Resend',
                                onClick: () =>
                                  resendInvitation({
                                    variables: {
                                      input: {
                                        memberId: member.id,
                                        motherId: institutionId,
                                      },
                                    },
                                  }),
                              },
                            ]),
                        {
                          key: 'delete',
                          label: 'Delete',
                          disabled: !isAllowedInstitutionMemberActions,
                          onClick: () => {
                            if (totalCount === 1) {
                              modal.alert.error({
                                title: 'Delete last member',
                              })
                              return
                            }

                            if (member.isPartner) {
                              modal.confirm.danger({
                                title: 'Remove partner user from this institution',
                                confirmText: 'Remove',
                                onConfirm: async () => {
                                  await removePartnerUser({
                                    variables: {
                                      input: {
                                        motherId: institutionId,
                                        memberId: member.id,
                                      },
                                    },
                                  })
                                  refetch()
                                },
                              })
                            } else {
                              modal.confirm.danger({
                                title: 'Delete user',
                                confirmText: 'Delete',
                                onConfirm: async () => {
                                  await deleteMember({
                                    variables: {
                                      input: {
                                        motherId: institutionId,
                                        memberId: member.id,
                                      },
                                    },
                                  })
                                  refetch()
                                },
                              })
                            }
                          },
                        },
                      ],
                    }}
                  />
                )
              },
            },
          ]}
          pagination={false}
        />

        {memberToEdit && institution && (
          <EditMemberModal
            institution={institution}
            member={null}
            memberToEdit={memberToEdit}
            open={Boolean(memberToEdit)}
            onClose={() => setMemberToEdit(null)}
            onCompleted={refetch}
          />
        )}

        {createMemberVisible && institution && (
          <CreateMemberModal
            open
            member={null}
            institution={institution}
            onCompleted={() => refetch()}
            onClose={() => setCreateMemberVisible(false)}
          />
        )}

        <GrantAccessActionModal
          visible={grantAccessVisible}
          close={() => setGrantAccessVisible(false)}
          users={members ?? []}
          institution={Institution.Company}
          institutionId={institutionId}
        />

        <TransferOwnershipModal
          member={memberToGrantOwnerShip}
          setMember={member =>
            setMemberToGrantOwnerShip(members.find(m => m.id === member?.id) ?? null)
          }
          institutionId={institutionId}
        />

        <Pagination
          style={styles.pagination}
          pageSize={pageSize}
          current={page}
          total={totalCount || 0}
          onChange={newPage => setPage(newPage)}
          showSizeChanger={false}
        />
      </Card>
    </MotherProvider>
  )
}

const styles = {
  pagination: { textAlign: 'right', marginTop: 20 },
} as const

export default MembersTable
