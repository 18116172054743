import { LocalizationProvider } from '@faceup/localization'
import { UserProvider } from '@faceup/member'
import { EnvironmentMessage, UiProvider as FUUiProvider } from '@faceup/ui'
import { UiBaseProvider, useMessage } from '@faceup/ui-base'
import { Country, DEFAULT_LANGUAGE, convertCountryToCountryName } from '@faceup/utils'
import type { PropsWithChildren, ReactNode } from 'react'
import GeoDataProvider from './Contexts/GeoDataProvider'

type AppProvidersProps = { children: ReactNode }

const AppProviders = ({ children }: AppProvidersProps) => (
  <LocalizationProvider language={DEFAULT_LANGUAGE}>
    <GeoDataProvider>
      <UiBaseProvider locale={undefined} direction='ltr'>
        <UiProvider>
          <UserProvider application='kredenc' onLogout={() => null} onChangeLanguage={() => null}>
            {children}
          </UserProvider>
        </UiProvider>
      </UiBaseProvider>
    </GeoDataProvider>
  </LocalizationProvider>
)

const UiProvider = ({ children }: PropsWithChildren) => {
  const message = useMessage()

  return (
    <FUUiProvider
      linkExternalConfirm={{
        confirmRedirectMessageTitle: 'Allow redirect',
        confirmRedirectMessageContent: 'Redirect to external site',
        redirectOkButton: 'Follow link',
        redirectCancelButton: 'Cancel',
      }}
      inputCopyable={{
        onCopySuccess: () => message.success('Copied'),
        copy: 'Copy',
      }}
      inputPhone={{
        searchPlaceholder: 'Search',
        localization: Object.values(Country).reduce(
          (acc, country) => ({
            ...acc,
            [country.toLowerCase()]: convertCountryToCountryName(country, 'en'),
          }),
          {}
        ),
        searchNotFound: 'Not found',
      }}
    >
      {children}
      {['stage', 'preview'].includes(import.meta.env.VITE_ENVIRONMENT ?? '') && (
        <EnvironmentMessage environment={import.meta.env.VITE_ENVIRONMENT ?? ''} />
      )}
    </FUUiProvider>
  )
}

export default AppProviders
