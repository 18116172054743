import { useMutation } from '@apollo/client'
import { Button, notification, useMessage, useModal } from '@faceup/ui-base'
import { query as institutionDetail } from '../Pages/InstitutionDetail'
import { graphql } from '../__generated__'

type Props = {
  motherId: string
  partnerId: string
}

const mutation = {
  UnlinkCompanyFromPartner: graphql(`
    mutation unlinkCompanyFromPartnerMutation(
      $input: UnlinkCompanyFromPartnerInput!
    ) {
      unlinkCompanyFromPartner(input: $input) {
        success
      }
    }
  `),
}

const UnlinkCompanyFromPartner = ({ partnerId, motherId }: Props) => {
  const message = useMessage()
  const modal = useModal()
  const [unlinkCompanyFromPartner, { loading: isLoading }] = useMutation(
    mutation.UnlinkCompanyFromPartner,
    {
      refetchQueries: [institutionDetail.InstitutionQuery],
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      onCompleted: data => {
        if (data.unlinkCompanyFromPartner?.success) {
          return message.success('Company unlinked')
        }
        return message.error(
          'Company not unlinked. Does it have at least one active member, except the partner one?'
        )
      },
    }
  )

  const showConfirmationModal = () => {
    modal.confirm.danger({
      title: `Unlink company from partner`,
      content: `Do you really want to unlink this company from partner? This will remove access of all partner-users to this company. If later you'd like to add the partner again the users will need to be added manually.`,
      onConfirm: () =>
        unlinkCompanyFromPartner({
          variables: {
            input: {
              partnerId,
              motherId,
            },
          },
        }),
    })
  }

  return (
    <Button ghost type='primary' onClick={() => showConfirmationModal()} loading={isLoading}>
      Unlink partner
    </Button>
  )
}

export default UnlinkCompanyFromPartner
