import { useQuery } from '@apollo/client'
import { Link, useParams } from '@faceup/router'
import { Card, ContentLayout, PageContentSkeleton } from '@faceup/ui'
import { Descriptions, Space, notification } from '@faceup/ui-base'
import { DEFAULT_COUNTRY, convertCountryToCountryName } from '@faceup/utils'
import moment from 'moment-timezone'
import PartnersTable from '../Components/PartnersTable'
import VerifyPartnerRegistrationButtons from '../Components/VerifyPartnerRegistrationButtons'
import RouteNotFound from '../RouteNotFound'
import { graphql } from '../__generated__'
import { DATE_FORMAT } from '../constants'

const query = {
  PartnerQuery: graphql(`
    query PartnerQuery($partnerId: PartnerInstitutionGlobalId!) {
      partner(partnerId: $partnerId) {
        id
        name
        verificationStatus
        country
        chargebeeCustomerUrl
        createdAt

        institutions {
          id
          config {
            id
            institutionName
          }
        }
      }
    }
  `),
}

const InstitutionDetail = () => {
  const { id } = useParams<'id'>()

  const { loading, data } = useQuery(query.PartnerQuery, {
    skip: !id,
    variables: { partnerId: id ?? '' },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  if (loading && !data) {
    return <PageContentSkeleton />
  }

  const institution = data?.partner
  if (!institution) {
    return <RouteNotFound />
  }

  return (
    <ContentLayout header={<ContentLayout.Header title={institution?.name} />}>
      <Space direction='vertical' size='large'>
        <Card>
          <Card.Header>Basic Info</Card.Header>
          <Card.Content>
            <Descriptions>
              <Descriptions.Item label='Status'>
                {institution?.verificationStatus}
              </Descriptions.Item>
              <Descriptions.Item label='Registered'>
                {institution?.createdAt && moment(institution?.createdAt).format(DATE_FORMAT)}
              </Descriptions.Item>
              <Descriptions.Item label='Country'>
                {convertCountryToCountryName(institution?.country ?? DEFAULT_COUNTRY)}
              </Descriptions.Item>
              {institution?.chargebeeCustomerUrl && (
                <Descriptions.Item label='Subscription info'>
                  <a
                    target='_blank'
                    href={institution?.chargebeeCustomerUrl ?? ''}
                    rel='noreferrer noopener'
                  >
                    Chargebee
                  </a>
                </Descriptions.Item>
              )}
              {institution.institutions.length > 0 && (
                <Descriptions.Item label='Linked institutions'>
                  <div className='flex flex-col'>
                    {institution.institutions.map(institution => (
                      <Link
                        key={institution.id}
                        to={routes => routes.institution({ id: institution.id })}
                      >
                        {institution.config.institutionName}
                      </Link>
                    ))}
                  </div>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>Actions</Card.Header>
          <Card.Content>
            <Space style={{ flexWrap: 'wrap' }}>
              <VerifyPartnerRegistrationButtons
                partnerId={institution?.id}
                verificationStatus={institution?.verificationStatus}
              />
            </Space>
          </Card.Content>
        </Card>
        <PartnersTable institutionId={institution?.id} />
      </Space>
    </ContentLayout>
  )
}

export default InstitutionDetail
