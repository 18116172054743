import { useMutation, useQuery } from '@apollo/client'
import { ContentLayout } from '@faceup/ui'
import { Button, Popconfirm, Table, notification, useMessage } from '@faceup/ui-base'
import { graphql } from '../__generated__'

const query = {
  GlobalTablesQuery: graphql(`
    query GlobalTablesQuery {
      globalTables
    }
  `),
}

const mutations = {
  truncateGlobalTable: graphql(`
    mutation TruncateGlobalTableMutation($input: TruncateGlobalTableInput!) {
      truncateGlobalTable(input: $input) {
        success
      }
    }
  `),
}

const Institutions = () => {
  const message = useMessage()
  const { loading, data } = useQuery(query.GlobalTablesQuery, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  const [truncateGlobalTable] = useMutation(mutations.truncateGlobalTable, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data?.truncateGlobalTable?.success) {
        message.success('Table truncated')
      } else {
        message.error('Error truncating table')
      }
    },
  })

  const globalTables = data?.globalTables?.map(table => ({
    table,
    truncateTable: () => truncateGlobalTable({ variables: { input: { table } } }),
  }))

  return (
    <ContentLayout header={<ContentLayout.Header title='Global tables' />}>
      <Table
        shadow
        loading={loading}
        columns={[
          {
            title: 'Table name',
            dataIndex: 'table',
            width: '80%',
          },
          {
            title: 'Actions',
            width: '20%',
            render: ({ truncateTable }) => (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Popconfirm
                  title='Truncating global table will delete all data across all regions. Are you sure?'
                  onConfirm={() => truncateTable()}
                  placement='topRight'
                >
                  <Button ghost danger>
                    Truncate table
                  </Button>
                </Popconfirm>
              </div>
            ),
          },
        ]}
        dataSource={globalTables ?? []}
        pagination={false}
        style={{ minHeight: 300 }}
        scroll={{ x: 'max-content' }}
      />
    </ContentLayout>
  )
}

export default Institutions
