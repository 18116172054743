import { useMutation, useQuery } from '@apollo/client'
import { Select, useForm, yup } from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { useLanguageName } from '@faceup/localization'
import { Card, ContentLayout, Form } from '@faceup/ui'
import { Button, Col, Row, Table, notification, useMessage, useModal } from '@faceup/ui-base'
import { Institution, Language, MaterialType } from '@faceup/utils'
import { useState } from 'react'
import MoreOptions from '../../Components/MoreOptions'
import { graphql } from '../../__generated__'
import { CreateMaterialModal } from './components'

const mutations = {
  DeleteInternalMaterial: graphql(`
    mutation DeleteInternalMaterialMutation($input: DeleteInternalMaterialInput!) {
      deleteInternalMaterial(input: $input) {
        success
      }
    }
  `),
}

const query = graphql(`
  query MaterialsViewQuery(
    $institutionType: Institution
    $type: MaterialType
    $language: Language
  ) {
    materials(
      institutionType: $institutionType
      language: $language
      type: $type
    ) {
      id
      type
      generatedDocument
      language
      institutionType
      name
      s3Key
      isFree
      url
    }
  }
`)

const schema = yup.object({
  language: yup.string().oneOf(Object.values(Language)).nullable(),
  institution: yup.string().oneOf(Object.values(Institution)).nullable(),
  type: yup.string().oneOf(Object.values(MaterialType)).nullable(),
})

const MaterialsView = () => {
  const { getLanguageList } = useLanguageName()
  const [isCreateMaterialModalOpened, setIsCreateMaterialModalOpened] = useState(false)
  const message = useMessage()
  const modal = useModal()

  const form = useForm({
    schema,
    afterSubmit: 'nothing',
    defaultValues: {
      language: null,
      institution: null,
      type: null,
    },
  })

  const { data, loading, refetch } = useQuery(query, {
    variables: {
      institutionType: form.watch('institution') ?? null,
      language: form.watch('language') ?? null,
      type: form.watch('type') ?? null,
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
  })

  const [deleteInternalMaterial] = useMutation(mutations.DeleteInternalMaterial, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data.deleteInternalMaterial?.success) {
        message.success(`Material deleted`)
        refetch()
      }
    },
  })

  const materials = data?.materials ?? []

  return (
    <ContentLayout header={<ContentLayout.Header title='Materials' />}>
      <div className='space-y-4'>
        <Card>
          <div className='flex justify-between items-end p-4'>
            <Form>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Select
                    label='Language'
                    name='language'
                    control={form.control}
                    options={getLanguageList().map(language => ({
                      label: language.name,
                      value: language.language,
                    }))}
                    allowClear
                  />
                </Col>
                <Col span={6}>
                  <Select
                    label='Institution'
                    name='institution'
                    control={form.control}
                    options={Object.values(Institution).map(institution => ({
                      label: institution,
                      value: institution,
                    }))}
                    allowClear
                  />
                </Col>
                <Col span={6}>
                  <Select
                    label='Type'
                    name='type'
                    control={form.control}
                    options={Object.values(MaterialType).map(materialType => ({
                      label: materialType,
                      value: materialType,
                    }))}
                    allowClear
                  />
                </Col>
              </Row>
            </Form>
            <Button
              type='primary'
              icon={<UntitledIcon icon={ulPlus} />}
              onClick={() => setIsCreateMaterialModalOpened(true)}
            >
              Add material
            </Button>
          </div>
        </Card>

        <Table
          shadow
          loading={loading}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
            },
            {
              title: 'Type',
              dataIndex: 'type',
            },
            {
              title: 'Language',
              dataIndex: 'language',
            },
            {
              title: 'Institution type',
              dataIndex: 'institutionType',
            },
            {
              title: 'S3 key',
              render: material => {
                return material.url ? <a href={material.url}>{material.s3Key}</a> : null
              },
            },
            {
              title: 'Is available also to free schools?',
              render: ({ isFree }) => (isFree ? 'Yes' : 'No'),
            },
            {
              title: 'Generated document',
              render: ({ generatedDocument }) => (
                <>
                  {
                    // conditionally display some icon if isForSchoolDefaultForm / withActivePassword?
                    generatedDocument
                  }
                </>
              ),
            },
            {
              title: '',
              render: material => (
                <MoreOptions
                  menu={{
                    items: [
                      {
                        key: 'delete',
                        label: 'Delete',
                        onClick: () => {
                          modal.confirm.danger({
                            title: `Delete internal material`,
                            content: `Do you really want to delete this material? It can't be undone.`,
                            onConfirm: async () => {
                              await deleteInternalMaterial({
                                variables: {
                                  input: {
                                    s3Key: material.s3Key,
                                  },
                                },
                              })
                              await refetch()
                            },
                          })
                        },
                      },
                    ],
                  }}
                />
              ),
            },
          ]}
          pagination={false}
          dataSource={materials}
        />
      </div>
      {isCreateMaterialModalOpened && (
        <CreateMaterialModal
          selectedLanguage={form.watch('language') ?? null}
          selectedInstitution={form.watch('institution') ?? null}
          selectedType={form.watch('type') ?? null}
          open
          onClose={() => {
            setIsCreateMaterialModalOpened(false)
          }}
          onSuccess={() => {
            setIsCreateMaterialModalOpened(false)
            refetch()
          }}
        />
      )}
    </ContentLayout>
  )
}

export default MaterialsView
