import { useMutation } from '@apollo/client'
import { Checkbox, ModalForm, Select, TextInput, useForm, yup } from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { ulUpload01 } from '@faceup/icons/ulUpload01'
import { useLanguageName } from '@faceup/localization'
import { Button, type ModalProps, Space, Upload, notification } from '@faceup/ui-base'
import {
  ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP,
  Institution,
  Language,
  MaterialGenerationType,
  MaterialType,
  materialTypeDefinition,
} from '@faceup/utils'
import { useEffect } from 'react'
import { graphql } from '../../../__generated__'

type CreateMaterialModalProps = Pick<ModalProps, 'open' | 'onClose'> & {
  selectedLanguage: Language | null
  selectedInstitution: Institution | null
  selectedType: MaterialType | null
  onSuccess: () => void
}

const mutations = {
  CreateInternalMaterial: graphql(`
    mutation CreateInternalMaterialMutation($input: CreateInternalMaterialInput!) {
      createInternalMaterial(input: $input) {
        newMaterial {
          id
        }
      }
    }
  `),
}

const schema = yup.object().shape({
  name: yup.string().required(),
  file: yup.mixed<Blob | File>().required().nullable(),
  type: yup.mixed<MaterialType>().oneOf(Object.values(MaterialType)).required().nullable(),
  generatedDocument: yup
    .mixed<MaterialGenerationType>()
    .oneOf(Object.values(MaterialGenerationType))
    .required()
    .nullable(),
  isForSchoolDefaultForm: yup.boolean(),
  withActivePassword: yup.boolean(),
  institution: yup.mixed<Institution>().oneOf(Object.values(Institution)).required().nullable(),
  language: yup.mixed<Language>().oneOf(Object.values(Language)).required().nullable(),
  isFree: yup.boolean(),
})

export const CreateMaterialModal = (props: CreateMaterialModalProps) => {
  const { selectedLanguage, selectedInstitution, selectedType } = props
  const { getLanguageList } = useLanguageName()

  const form = useForm({
    schema,
    defaultValues: {
      name: '',
      file: null,
      type: selectedType,
      generatedDocument: null,
      isForSchoolDefaultForm: false,
      withActivePassword: false,
      institution: selectedInstitution,
      language: selectedLanguage,
      isFree: false,
    },
    afterSubmit: 'resetValues',
  })
  const [createInternalMaterial] = useMutation(mutations.CreateInternalMaterial, {
    onError: error => {
      console.error(error)
      notification.error({
        message: `Can't create material`,
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data.createInternalMaterial?.newMaterial.id) {
        props.onSuccess()
      }
    },
  })

  const { watch, setValue } = form
  const [generatedDocument, institution, isForSchoolDefaultForm, withActivePassword] = watch([
    'generatedDocument',
    'institution',
    'isForSchoolDefaultForm',
    'withActivePassword',
  ])

  useEffect(() => {
    if (
      generatedDocument !== MaterialGenerationType.Leaflet ||
      institution !== Institution.School
    ) {
      if (isForSchoolDefaultForm) {
        setValue('isForSchoolDefaultForm', false)
      }
      if (withActivePassword) {
        setValue('withActivePassword', false)
      }
    }
  }, [generatedDocument, institution, isForSchoolDefaultForm, withActivePassword, setValue])

  return (
    <ModalForm
      {...props}
      title='Add material'
      form={form}
      submitButtonText='add'
      customSubmitSuccessText='Material added'
      onSubmit={async values => {
        if (
          values.language &&
          values.institution &&
          values.type &&
          values.generatedDocument &&
          values.file
        ) {
          await createInternalMaterial({
            variables: {
              input: {
                name: values.name,
                language: values.language,
                type: values.type,
                generatedDocument: values.generatedDocument,
                isForSchoolDefaultForm: values.isForSchoolDefaultForm ?? false,
                withActivePassword: values.withActivePassword ?? false,
                institution: values.institution,
                file: values.file,
                isFree: values.isFree ?? false,
              },
            },
          })
          return true
        }
        return false
      }}
    >
      <TextInput control={form.control} name='name' label='Name' />
      <Upload
        accept={Object.keys(ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP).join(',')}
        beforeUpload={(file: File) => {
          form.setValue('file', file)
          return false
        }}
        onRemove={() => {
          form.setValue('file', null)
        }}
        showUploadList
      >
        <Button ghost type='primary'>
          <Space>
            <UntitledIcon icon={ulUpload01} />
            Upload file
          </Space>
        </Button>
      </Upload>
      <Select
        control={form.control}
        name='type'
        options={materialTypeDefinition.map(type => ({
          label: type,
          value: type,
        }))}
        label='Type'
      />
      <Select
        control={form.control}
        name='generatedDocument'
        options={Object.values(MaterialGenerationType).map(value => ({
          label: value,
          value,
        }))}
        label='Generated document'
      />
      <Select
        control={form.control}
        name='language'
        options={getLanguageList().map(language => ({
          label: language.name,
          value: language.language,
        }))}
        label='Language'
      />
      <Select
        control={form.control}
        name='institution'
        options={Object.values(Institution).map(value => ({
          label: value,
          value,
        }))}
        label='Institution'
      />
      <Checkbox control={form.control} name='isFree' label='Is available also to free schools?' />
      <Space />
      {form.getValues('generatedDocument') === MaterialGenerationType.Leaflet &&
        form.getValues('institution') === Institution.School && (
          <>
            <Checkbox
              control={form.control}
              name='isForSchoolDefaultForm'
              label='For default school form'
            />
            <Checkbox
              // only default forms can have password switched on / off
              disabled={!form.getValues('isForSchoolDefaultForm')}
              control={form.control}
              name='withActivePassword'
              label='For schools with an active password'
            />
          </>
        )}
    </ModalForm>
  )
}
