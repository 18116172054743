import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Card, ContentLayout, usePaginator } from '@faceup/ui'
import {
  Checkbox,
  Col,
  Row,
  Space,
  Switch,
  Table,
  type TableColumns,
  notification,
} from '@faceup/ui-base'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { useState } from 'react'
import { graphql } from '../__generated__'
import { DATE_FORMAT, ROWS_PER_PAGE } from '../constants'

const query = {
  ReportsTableQuery: graphql(`
    query ReportsTableQuery(
      $page: Int!
      $rowsPerPage: Int!
      $emailsSent: Boolean!
    ) {
      unregisteredCases(
        page: $page
        rowsPerPage: $rowsPerPage
        emailsSent: $emailsSent
      ) {
        totalCount
        items {
          id
          ... on CompanyReport {
            emailsSent
          }
          closed
          createdAt
        }
      }
      psychologistCases(
        page: $page
        rowsPerPage: $rowsPerPage
        emailsSent: $emailsSent
      ) {
        id
        victimName
        moreInfo
      }
    }
  `),
}

const makeTableRow = async (
  aCase: NonNullable<NonNullable<ReportsTableQuery['unregisteredCases']>['items']>[number] | null,
  name: string
) => {
  return {
    key: aCase?.id,
    name: [aCase?.id, name],
    closed: aCase?.closed,
    emailsSent: aCase?.__typename === 'CompanyReport' ? aCase.emailsSent : false,
    createdAt: aCase?.createdAt,
  }
}

type ReportsTableQuery = ResultOf<typeof query.ReportsTableQuery>

const columns: TableColumns<Awaited<ReturnType<typeof makeTableRow>>> = [
  {
    title: 'Name',
    width: '70%',
    render: ({ name: [reportId, name] }) => {
      return <Link to={routes => routes.report({ id: reportId ?? '' })}>{name}</Link>
    },
  },
  {
    title: 'Emails sent',
    width: '10%',
    render: ({ emailsSent }) => <Checkbox disabled defaultChecked={emailsSent} />,
  },
  {
    title: 'Created date',
    width: '10%',
    render: ({ createdAt }) => createdAt && moment(createdAt).format(DATE_FORMAT),
  },
]

const Reports = () => {
  const [emailsSent, setEmailsSent] = useState(false)
  const [cases, setCases] = useState<Awaited<ReturnType<typeof makeTableRow>>[]>([])
  const { getTablePaginator, page, rowsPerPage } = usePaginator({
    rowsPerPage: ROWS_PER_PAGE,
  })

  const { loading, data } = useQuery(query.ReportsTableQuery, {
    variables: {
      page,
      rowsPerPage,
      emailsSent,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: async data => {
      const reportItems = data?.unregisteredCases?.items ?? []
      const psychologistReports = data?.psychologistCases ?? []

      const psychologistReportMap = new Map(psychologistReports.map(report => [report?.id, report]))

      const reportsNodes = await Promise.all(
        reportItems?.map(report => {
          const psychologistReport = psychologistReportMap.get(report?.id)
          return makeTableRow(report ?? null, psychologistReport?.victimName ?? '')
        })
      )
      setCases(reportsNodes.filter(report => report?.key))
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  return (
    <ContentLayout header={<ContentLayout.Header title='Reports' />}>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Card>
          <Row gutter={16} style={{ padding: '24px' }}>
            <Col xs={24} sm={12} md={6}>
              <InputLabel>Emails sent</InputLabel>
              <br />
              <Switch checked={emailsSent} onChange={checked => setEmailsSent(checked)} />
            </Col>
          </Row>
        </Card>
        <Table
          shadow
          loading={loading}
          columns={columns}
          dataSource={cases}
          pagination={getTablePaginator({
            totalRows: data?.unregisteredCases?.totalCount ?? 0,
          })}
          style={{ minHeight: 300 }}
          scroll={{ x: 'max-content' }}
        />
      </Space>
    </ContentLayout>
  )
}

const InputLabel = styled.span`
  display: inline-block;
  margin: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
`

export default Reports
