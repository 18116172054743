import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Card, ContentLayout, usePaginator } from '@faceup/ui'
import {
  Col,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  type TableColumns,
  notification,
} from '@faceup/ui-base'
import {
  ChargebeeSubscriptionStatus,
  Country,
  DEFAULT_COUNTRY,
  Institution,
  Plan,
  convertCountryToCountryName,
  planMapName,
} from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { useState } from 'react'
import DotText from '../Components/DotText'
import { graphql } from '../__generated__'
import { DATE_FORMAT, ROWS_PER_PAGE } from '../constants'
import CreateCompany from './Companies/CreateCompany'

const query = {
  InstitutionsTableQuery: graphql(`
    query InstitutionsTableQuery(
      $page: Int!
      $name: GraphQLString
      $institutionType: Institution!
      $orders: [OrderByCompanies]
      $plan: [Plan]
      $rowsPerPage: Int!
      $country: Country
      $isVerified: Boolean
      $memberEmail: GraphQLString
    ) {
      companies(
        page: $page
        name: $name
        institutionType: $institutionType
        rowsPerPage: $rowsPerPage
        orders: $orders
        plan: $plan
        isMother: true
        country: $country
        isVerified: $isVerified
        memberEmail: $memberEmail
      ) {
        totalCount
        items {
          id
          parentId
          country
          countOfAllCompanyReports
          registered
          isPendingVerification
          isVerified
          createdAt

          billing {
            id
            subscriptionStatus
            plan
          }

          config {
            id
            institutionName
          }
        }
      }
    }
  `),
}

const columns: TableColumns<
  NonNullable<ResultOf<typeof query.InstitutionsTableQuery>['companies']>['items'][number]
> = [
  {
    title: 'Name',
    width: '34%',
    render: institution => (
      <Link to={routes => routes.institution({ id: institution.id })}>
        {institution.config.institutionName}
      </Link>
    ),
  },
  {
    title: 'Count of reports',
    dataIndex: 'countOfAllCompanyReports',
    width: '12%',
  },
  {
    title: 'Country',
    width: '12%',
    render: ({ country }) => convertCountryToCountryName(country ?? DEFAULT_COUNTRY, 'en'),
  },
  {
    title: 'Plan',
    width: '15%',
    render: institution => {
      const registered = institution?.registered
      const isPendingVerification = institution?.isPendingVerification
      if (registered) {
        return <DotText color='#9649Cb'>Registered</DotText>
      }

      if (isPendingVerification) {
        return <DotText color='#ff9b42'>Pending verification</DotText>
      }

      return <DotText color='#ff0035'>Unregistered</DotText>
    },
  },
  {
    title: 'Version',
    width: '11%',
    render: ({ billing }) => {
      if (!billing) {
        return null
      }
      const plan = billing.plan
      if (plan === Plan.Free) {
        return <DotText color='#2eb271'>{planMapName[plan]}</DotText>
      }

      if (plan === Plan.Basic) {
        return <DotText color='#9649Cb'>{planMapName[plan]}</DotText>
      }

      if (plan === Plan.Standard) {
        return <DotText color='#ff9b42'>{planMapName[plan]}</DotText>
      }

      if (plan === Plan.Premium) {
        return <DotText color='#ff0035'>{planMapName[plan]}</DotText>
      }

      return null
    },
  },
  {
    title: 'Payment',
    width: '7%',
    render: ({ billing }) => {
      if (!billing) {
        return null
      }
      const payment = billing.subscriptionStatus
      if (payment === ChargebeeSubscriptionStatus.InTrial) {
        return <DotText color='#2eb271'>{payment}</DotText>
      }

      if (payment === ChargebeeSubscriptionStatus.Cancelled) {
        return <DotText color='#9649Cb'>{payment}</DotText>
      }

      if (payment === ChargebeeSubscriptionStatus.Active) {
        return <DotText color='#ff9b42'>{payment}</DotText>
      }

      if (payment === ChargebeeSubscriptionStatus.Future) {
        return <DotText color='#ff0035'>{payment}</DotText>
      }

      return payment
    },
  },
  {
    title: 'Registration date',
    width: '12%',
    render: billing => billing?.createdAt && moment(billing.createdAt).format(DATE_FORMAT),
  },
]

type Props = {
  type: Institution
}

const Institutions = ({ type }: Props) => {
  const [name, setName] = useState('')
  const [country, setCountry] = useState<Country>()
  const [plan, setPlan] = useState<Plan>()
  const [email, setEmail] = useState('')
  const [waitingForRegistration, setWaitingForRegistration] = useState(false)
  const { getTablePaginator, page, rowsPerPage } = usePaginator({ rowsPerPage: ROWS_PER_PAGE })

  const { loading, data } = useQuery(query.InstitutionsTableQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page,
      name,
      country,

      plan: plan
        ? [plan]
        : waitingForRegistration
          ? [Plan.Free, Plan.Basic, Plan.Standard, Plan.Premium]
          : undefined,
      institutionType: type,
      rowsPerPage,
      isVerified: waitingForRegistration ? !waitingForRegistration : undefined,
      memberEmail: email ? email : undefined,
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  return (
    <ContentLayout
      header={
        <ContentLayout.Header
          title={type === Institution.Company ? 'Companies' : 'Schools'}
          secondaryContent={<CreateCompany institutionType={type} />}
        />
      }
    >
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Card>
          <Row gutter={16} style={{ padding: '24px' }}>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Name</InputLabel>
              <Input placeholder='Search' onChange={({ target: { value } }) => setName(value)} />
            </Col>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Plan</InputLabel>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Plan'
                onChange={setPlan}
                options={Object.values(Plan).map(plan => ({
                  label: planMapName[plan],
                  value: plan,
                }))}
              />
            </Col>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Country</InputLabel>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Country'
                onChange={setCountry}
                showSearch
                options={Object.values(Country).map(country => ({
                  label: convertCountryToCountryName(country ?? DEFAULT_COUNTRY, 'en'),
                  value: country,
                }))}
              />
            </Col>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Email</InputLabel>
              <br />
              <Input placeholder='Email' onChange={({ target: { value } }) => setEmail(value)} />
            </Col>
            <Col xs={24} sm={12} md={4}>
              <InputLabel>Waiting for registration</InputLabel>
              <br />
              <Switch
                checked={waitingForRegistration}
                onChange={checked => setWaitingForRegistration(checked)}
              />
            </Col>
          </Row>
        </Card>
        <Table
          shadow
          loading={loading}
          columns={columns}
          dataSource={data?.companies?.items ?? []}
          pagination={getTablePaginator({
            totalRows: data?.companies?.totalCount ?? 0,
          })}
          style={{ minHeight: 300 }}
          scroll={{ x: 'max-content' }}
        />
      </Space>
    </ContentLayout>
  )
}

const InputLabel = styled.span`
  display: inline-block;
  margin: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
`

export default Institutions
