import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from '@faceup/router'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { name, version } from '../package.json'
import App from './App'

if (import.meta.env.VITE_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://539b1161e2d54812b2b065c84f03dc58@o441414.ingest.sentry.io/5431071',
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        mask: ['.sensitive'],
      }),
    ],
    tracesSampleRate: 1,
    tracePropagationTargets: [import.meta.env.VITE_API_URL ?? ''],
    release: `${name.replace('@faceup/', '')}@${version}`, // after changing release name, it is required to change it in sourcemaps upload as well
    environment: import.meta.env.VITE_ENVIRONMENT ?? '',
    replaysSessionSampleRate: 0.33,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 6,
  })
}

const container = document.getElementById('app')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
