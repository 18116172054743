import { useQuery } from '@apollo/client'
import { Link, useParams } from '@faceup/router'
import { Card, ContentLayout, PageContentSkeleton } from '@faceup/ui'
import { Descriptions, Divider, Space, notification } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import CloseReportButton from '../Components/CloseReportButton'
import NonRegisteredEmails from '../Components/NonRegisteredEmails'
import RouteNotFound from '../RouteNotFound'
import { graphql } from '../__generated__'
import { DATE_FORMAT } from '../constants'

const query = {
  ReportDetailQuery: graphql(`
    query ReportDetailQuery($caseId: UUID!) {
      case(caseId: $caseId) {
        id
        ... on CompanyReport {
          emailsSent
          closed
        }
        answers {
          id
          values
          formItem {
            id
            type
          }
        }
        createdAt
        organizationalUnit {
          id
          organizationalUnitName
          country
          billing {
            id
            plan
          }

          isVerified
          isPendingVerification
        }
      }
      psychologistCase(caseId: $caseId) {
        id
        victimName
        moreInfo
      }
    }
  `),
}

const ReportDetail = () => {
  const { id } = useParams<'id'>()
  const [victimName, setVictimName] = useState('')
  const [moreInfo, setMoreInfo] = useState('')

  const { loading, data } = useQuery(query.ReportDetailQuery, {
    skip: !id,
    variables: { caseId: id ?? '' },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  useEffect(() => {
    const decryptReport = async () => {
      const report = data?.psychologistCase
      if (!report) {
        return
      }
      setVictimName(report.victimName ?? '')
      setMoreInfo(report.moreInfo ?? '')
    }

    decryptReport()
  }, [data?.psychologistCase])

  if (loading && !data) {
    return <PageContentSkeleton />
  }

  const aCase = data?.case
  const report = aCase?.__typename === 'CompanyReport' ? aCase : null

  if (!aCase) {
    return <RouteNotFound />
  }

  const classRoomValue = aCase.answers?.find(
    answer => answer.formItem.type === FormItemType.Classroom
  )?.values[0]

  return (
    <ContentLayout header={<ContentLayout.Header title='Report' />}>
      <Space direction='vertical' size='large'>
        <Card>
          <Card.Header>Basic Info</Card.Header>
          <Card.Content>
            <Descriptions>
              <Descriptions.Item label='Name'>{victimName}</Descriptions.Item>
              <Descriptions.Item label='Info'>{moreInfo}</Descriptions.Item>
              <Descriptions.Item label='Class room'>{classRoomValue}</Descriptions.Item>
              <Descriptions.Item label='Created'>
                {aCase.createdAt && moment(aCase.createdAt).format(DATE_FORMAT)}
              </Descriptions.Item>
              {report && (
                <Descriptions.Item label='E-mails sent'>
                  {report.emailsSent ? 'Yes' : 'No'}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>Institution</Card.Header>
          <Card.Content>
            <Space>
              <Descriptions>
                <Descriptions.Item label='Institution name'>
                  {aCase.organizationalUnit.organizationalUnitName}
                </Descriptions.Item>
                <Descriptions.Item label='Institution'>
                  <Link to={routes => routes.institution({ id: aCase.organizationalUnit.id })}>
                    {aCase.organizationalUnit.organizationalUnitName}
                  </Link>
                </Descriptions.Item>
              </Descriptions>
            </Space>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>Actions</Card.Header>
          <Card.Content>
            <Space direction='vertical' style={{ width: '100%', flexWrap: 'wrap' }}>
              {report && <CloseReportButton {...report} />}
              <Divider />
              {report && <NonRegisteredEmails {...report} />}
            </Space>
          </Card.Content>
        </Card>
      </Space>
    </ContentLayout>
  )
}

export default ReportDetail
