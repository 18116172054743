import { UntitledIcon } from '@faceup/icons'
import { ulLogOut04 } from '@faceup/icons/ulLogOut04'
import { UserAvatar } from '@faceup/ui'
import { Button, Dropdown } from '@faceup/ui-base'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  HeaderProfile_user: graphql(`
    fragment HeaderProfile_user on UserInterface {
      id
      name
      profileImageUrl
    }
  `),
}

type Props = {
  viewer: FragmentType<typeof fragments.HeaderProfile_user>
  logout: () => void
}

const HeaderProfile = ({ viewer: _viewer, logout }: Props) => {
  const viewer = getFragmentData(fragments.HeaderProfile_user, _viewer)
  const name = viewer.name

  return (
    <Dropdown
      menu={{
        items: [
          {
            icon: <UntitledIcon icon={ulLogOut04} />,
            key: 'logout',
            label: 'Logout',
            onClick: logout,
          },
        ],
      }}
      placement='bottomRight'
    >
      <Button type='text' icon={<UserAvatar user={viewer} />}>
        {name}
      </Button>
    </Dropdown>
  )
}

export default HeaderProfile
