import type { UntitledIconData } from '../../UntitledIconData'

const ulUpload01: UntitledIconData = {
  prefix: 'ul',
  name: 'upload-01',
  paths: [
    {
      shape:
        'M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15',
    },
  ],
}

export default ulUpload01
