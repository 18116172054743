import { useMutation } from '@apollo/client'
import { ModalForm, TextInput, useForm, yup } from '@faceup/form'
import { Button, Flex, notification, useModal } from '@faceup/ui-base'
import { ChannelType } from '@faceup/utils'
import { useState } from 'react'
import { graphql } from '../__generated__'

const mutations = {
  approveAutomatedHotlineMutation: graphql(`
    mutation ApproveAutomatedHotlineOrderMutation(
      $input: ApproveAutomatedHotlineOrderInput!
    ) {
      approveAutomatedHotlineOrder(input: $input) {
        success
      }
    }
  `),
  approveLiveHotlineMutation: graphql(`
    mutation ApproveLiveHotlineOrderMutation(
      $input: ApproveLiveHotlineOrderInput!
    ) {
      approveLiveHotlineOrder(input: $input) {
        success
      }
    }
  `),
  deleteAutomatedHotlineMutation: graphql(`
    mutation DeleteAutomatedHotlineOrderMutation(
      $input: DeleteAutomatedHotlineOrderInput!
    ) {
      deleteAutomatedHotlineOrder(input: $input) {
        success
      }
    }
  `),
  deleteLiveHotlineMutation: graphql(`
    mutation DeleteLiveHotlineOrderMutation($input: DeleteLiveHotlineOrderInput!) {
      deleteLiveHotlineOrder(input: $input) {
        success
      }
    }
  `),
}

type ManageHotlineModalProps = {
  channelId: string
  type: ChannelType.AutomatedHotline | ChannelType.LiveHotline
  refetch: () => void
  name: string
}

const ManageHotlineModal = ({ channelId, type, refetch, name }: ManageHotlineModalProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const modal = useModal()

  const [approveAutomatedHotline] = useMutation(mutations.approveAutomatedHotlineMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      setModalOpen(false)
      refetch()
    },
  })

  const [deleteAutomatedHotline] = useMutation(mutations.deleteAutomatedHotlineMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      notification.success({
        message: `${name} deleted`,
      })
      refetch()
    },
  })

  const [deleteLiveHotline] = useMutation(mutations.deleteLiveHotlineMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      notification.success({
        message: `${name} deleted`,
      })
      refetch()
    },
  })

  const [approveLiveHotline] = useMutation(mutations.approveLiveHotlineMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      setModalOpen(false)
      refetch()
    },
  })

  const handleDelete = () => {
    modal.confirm.danger({
      title: `Delete hotline ${name}`,
      confirmText: 'Delete',
      onConfirm: () => {
        if (type === ChannelType.AutomatedHotline) {
          deleteAutomatedHotline({
            variables: {
              input: { channelId },
            },
          })
        } else {
          deleteLiveHotline({
            variables: {
              input: { channelId },
            },
          })
        }
      },
    })
  }

  const errMsg =
    'Phone number should only contain digits with no spaces or special characters (search for E.164 notation)'

  const schema = yup.object({
    phoneNumber: yup.string().required().matches(/^\d+$/, errMsg).min(5).max(50),
  })

  const form = useForm({
    schema,
    afterSubmit: 'persistValues',
  })

  return (
    <>
      <Flex gap={8}>
        <Button type='primary' onClick={() => setModalOpen(true)}>
          Approve
        </Button>
        <Button danger onClick={handleDelete}>
          Delete
        </Button>
      </Flex>
      <ModalForm
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title='Approve hotline mutation'
        form={form}
        submitButtonText='save'
        onSubmit={async values => {
          const input = {
            phoneNumber: Number(values.phoneNumber),
            channelId,
          }
          if (type === ChannelType.LiveHotline) {
            await approveLiveHotline({ variables: { input } })
            return true
          }
          if (type === ChannelType.AutomatedHotline) {
            await approveAutomatedHotline({
              variables: { input },
            })
            return true
          }
          return false
        }}
      >
        <TextInput label='Phone number' control={form.control} name='phoneNumber' />
      </ModalForm>
    </>
  )
}

export default ManageHotlineModal
