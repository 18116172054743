import styled from '@emotion/styled'
import { Button, Divider, List, Modal, Popconfirm, Select, Typography } from '@faceup/ui-base'
import { Language } from '@faceup/utils'
import { useState } from 'react'

const { Text } = Typography

type Props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  languages: Language[]
  defaultLanguage: Language
  addLanguage: (language: Language) => void
  removeLanguage: (language: Language) => void
  setDefaultLanguage: (Language: Language) => void
}

const EditServiceLanguageModal = (props: Props) => {
  const [newLanguageMode, setNewLanguageMode] = useState(false)
  const [newLanguage, setNewLanguage] = useState<Language | null>(null)

  return (
    <Modal title='Edit language' open={props.visible} onClose={() => props.setVisible(false)}>
      <List
        dataSource={props.languages}
        renderItem={language => (
          <List.Item>
            <ListItemContent>
              {language ?? ''}
              <div>
                {language === props.defaultLanguage ? (
                  <Text strong style={{ marginRight: 15 }}>
                    Default language
                  </Text>
                ) : (
                  <>
                    <Popconfirm
                      title='Confirm removing'
                      okText='Remove'
                      cancelText='Cancel'
                      onConfirm={() => props.removeLanguage(language)}
                    >
                      <Button size='small' type='link' danger>
                        Remove
                      </Button>
                    </Popconfirm>
                    <Divider type='vertical' />
                    <Button
                      size='small'
                      type='link'
                      onClick={() => props.setDefaultLanguage(language)}
                    >
                      Set as default language
                    </Button>
                  </>
                )}
              </div>
            </ListItemContent>
          </List.Item>
        )}
        footer={
          newLanguageMode ? (
            <ListItemContent>
              <Select<Language>
                style={{ width: 250 }}
                onSelect={(value: Language) => setNewLanguage(value)}
                placeholder='Add language...'
                options={Object.values(Language)
                  .filter(language => !props.languages.includes(language))
                  .map(language => ({ label: language, value: language }))}
              />
              <div>
                <Button size='small' type='link' danger onClick={() => setNewLanguageMode(false)}>
                  Cancel
                </Button>
                <Divider type='vertical' />
                <Button
                  size='small'
                  type='link'
                  onClick={() => {
                    if (newLanguage) {
                      props.addLanguage(newLanguage)
                    }
                    setNewLanguageMode(false)
                    setNewLanguage(null)
                  }}
                >
                  Add
                </Button>
              </div>
            </ListItemContent>
          ) : (
            <Button ghost type='primary' block onClick={() => setNewLanguageMode(true)}>
              Add language
            </Button>
          )
        }
      />
    </Modal>
  )
}

const ListItemContent = styled.div`
  display: flex;
  width: 100%;
  flex-flow: nowrap row;
  justify-content: space-between;
  align-items: center;
`

export default EditServiceLanguageModal
