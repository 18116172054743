import { useMutation } from '@apollo/client'
import { ModalConfirm, notification, useMessage } from '@faceup/ui-base'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  TransferOwnershipModal_member: graphql(`
    fragment TransferOwnershipModal_member on Member {
      id
      name
    }
  `),
}

const mutations = {
  TransferOwnership: graphql(`
    mutation TransferOwnershipMutation($memberId: UUID!, $motherId: UUID!) {
      transferOwnership(input: { memberId: $memberId, motherId: $motherId }) {
        oldOwner {
          id
          accountType(motherId: $motherId)
        }

        newOwner {
          id
          accountType(motherId: $motherId)
        }
      }
    }
  `),
}
export const TransferOwnershipModal = ({
  member: _member,
  setMember,
  institutionId,
}: {
  member: FragmentType<typeof fragments.TransferOwnershipModal_member> | null
  setMember: (member: ResultOf<typeof fragments.TransferOwnershipModal_member> | null) => void
  institutionId: string
}) => {
  const member = getFragmentData(fragments.TransferOwnershipModal_member, _member)
  const message = useMessage()
  const [transfer, { loading }] = useMutation(mutations.TransferOwnership, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: () => {
      message.success('Ownership transferred successfully')
      setMember(null)
    },
  })

  return member ? (
    <ModalConfirm
      title='Transfer ownership'
      open={!!member}
      onCancel={() => setMember(null)}
      onConfirm={async () => {
        transfer({
          variables: {
            memberId: member.id,
            motherId: institutionId,
          },
        })
      }}
      confirmState={{ loading }}
    >
      Are you sure you want to transfer ownership to <strong>{member.name}</strong>?
    </ModalConfirm>
  ) : null
}
