import { useQuery } from '@apollo/client'
import { Select, Switch, TextInput, useForm, yup } from '@faceup/form'
import { Link } from '@faceup/router'
import { Card, ContentLayout, Form, usePaginator } from '@faceup/ui'
import { Space, Table, type TableColumns, notification } from '@faceup/ui-base'
import { Col, Row } from '@faceup/ui-base'
import { Country, VerificationStatus, convertCountryToCountryName } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { useDebouncedValue } from '@mantine/hooks'
import moment from 'moment-timezone'
import DotText from '../Components/DotText'
import { graphql } from '../__generated__'
import { DATE_FORMAT, ROWS_PER_PAGE } from '../constants'

const query = {
  PartnersTableQuery: graphql(`
    query PartnersTableQuery(
      $page: Int!
      $rowsPerPage: Int!
      $name: GraphQLString
      $country: Country
      $email: GraphQLString
      $waitingForRegistration: Boolean
    ) {
      partners(
        page: $page
        rowsPerPage: $rowsPerPage
        name: $name
        country: $country
        email: $email
        waitingForRegistration: $waitingForRegistration
      ) {
        totalCount
        items {
          id
          name
          verificationStatus
          createdAt
        }
      }
    }
  `),
}

const columns: TableColumns<
  NonNullable<ResultOf<typeof query.PartnersTableQuery>['partners']>['items'][number]
> = [
  {
    title: 'Name',
    width: '35%',
    render: partner => (
      <Link to={routes => routes.partner({ id: partner.id })}>{partner.name}</Link>
    ),
  },
  {
    title: 'Status',
    width: '15%',
    render: ({ verificationStatus }) => {
      if (verificationStatus === VerificationStatus.Pending) {
        return <DotText color='#ff9b42'>{verificationStatus}</DotText>
      }
      if (verificationStatus === VerificationStatus.Approved) {
        return <DotText color='#2eb271'>{verificationStatus}</DotText>
      }

      return <DotText color='#ff0035'>{verificationStatus}</DotText>
    },
  },
  {
    title: 'Registration date',
    width: '10%',
    render: ({ createdAt }) => createdAt && moment(createdAt).format(DATE_FORMAT),
  },
]

const Partners = () => {
  const { getTablePaginator, page, rowsPerPage } = usePaginator({ rowsPerPage: ROWS_PER_PAGE })

  const schema = yup.object({
    name: yup.string().nullable(),
    country: yup.string().oneOf(Object.values(Country)).nullable(),
    email: yup.string().email().nullable(),
    waitingForRegistration: yup.boolean(),
  })

  const form = useForm({
    schema,
    afterSubmit: 'nothing',
    defaultValues: {
      name: null,
      country: null,
      email: null,
      waitingForRegistration: false,
    },
  })

  const [debouncedName] = useDebouncedValue(form.watch('name'), 300)
  const [debouncedEmail] = useDebouncedValue(form.watch('email'), 300)
  const [debouncedWaitingForRegistration] = useDebouncedValue(
    form.watch('waitingForRegistration'),
    300
  )

  const { loading, data } = useQuery(query.PartnersTableQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page,
      rowsPerPage,
      name: debouncedName,
      country: form.watch('country'),
      email: debouncedEmail,
      waitingForRegistration: debouncedWaitingForRegistration,
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })

  return (
    <ContentLayout header={<ContentLayout.Header title='Partners' />}>
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Card>
          <Form>
            <Row gutter={[16, 16]} className='p-6'>
              <Col span={6}>
                <TextInput label='Name' control={form.control} name='name' />
              </Col>
              <Col span={6}>
                <Select
                  label='Country'
                  name='country'
                  control={form.control}
                  allowClear
                  options={Object.values(Country).map(code => ({
                    label: convertCountryToCountryName(code),
                    value: code,
                  }))}
                />
              </Col>
              <Col span={6}>
                <TextInput label='Email' control={form.control} name='email' />
              </Col>
              <Col span={6}>
                <Switch
                  label='Waiting for registration'
                  control={form.control}
                  name='waitingForRegistration'
                />
              </Col>
            </Row>
          </Form>
        </Card>
        <Table
          shadow
          loading={loading}
          columns={columns}
          dataSource={data?.partners?.items ?? []}
          pagination={getTablePaginator({ totalRows: data?.partners?.totalCount ?? 0 })}
          style={{ minHeight: 300 }}
          scroll={{ x: 'max-content' }}
        />
      </Space>
    </ContentLayout>
  )
}

export default Partners
