import { Link } from '@faceup/router'
import { Button, Result } from '@faceup/ui-base'

const RouteNotFound = () => (
  <Result
    status='404'
    title='404'
    subTitle='Page not found'
    extra={
      <Link to={routes => routes.home()}>
        <Button ghost type='primary'>
          Go back to dashboard
        </Button>
      </Link>
    }
  />
)

export default RouteNotFound
