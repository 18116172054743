import { useQuery } from '@apollo/client'
import { Card } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { ChannelType } from '@faceup/utils'
import { graphql } from '../__generated__'
import ReportSourcesHotlineTable from './ReportSourcesHotlineTable'
import ReportSourcesTable from './ReportSourcesTable'

const query = {
  ReportSourcesQuery: graphql(`
    query ReportSourcesQuery($motherId: UUID!) {
      reportSources(
        motherId: $motherId
        page: 0
        rowsPerPage: 100
        types: [ReportingSystem, AutomatedHotline, LiveHotline]
      ) {
        items {
          id
          sourceType
          ...ReportSourcesTable_reportSource
          ...ReportSourceHotlineTable_hotline
        }
      }
    }
  `),
}

type Props = {
  institutionId: string
}

const ReportSourcesCard = ({ institutionId }: Props) => {
  const { data, loading, refetch } = useQuery(query.ReportSourcesQuery, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    fetchPolicy: 'cache-and-network',
    variables: {
      motherId: institutionId,
    },
  })

  const reportSources = data?.reportSources?.items ?? []

  const groupedReportSources = reportSources.reduce(
    (acc, item) => ({
      ...acc,
      [item.sourceType]: [...acc[item.sourceType], item],
    }),
    {
      [ChannelType.AutomatedHotline]: [],
      [ChannelType.LiveHotline]: [],
      [ChannelType.ReportingSystem]: [],
      [ChannelType.Survey]: [],
    }
  )

  return (
    <Card>
      <Card.Section>
        <Card.Header>Reporting forms</Card.Header>
        <ReportSourcesTable
          reportSources={[
            ...groupedReportSources[ChannelType.ReportingSystem],
            ...groupedReportSources[ChannelType.Survey],
          ]}
          loading={loading}
        />
      </Card.Section>
      <ReportSourcesHotlineTable
        title='Automated Hotline'
        reportSources={groupedReportSources[ChannelType.AutomatedHotline]}
        refetch={refetch}
        loading={loading}
      />
      <ReportSourcesHotlineTable
        title='Live Hotline'
        reportSources={groupedReportSources[ChannelType.LiveHotline]}
        refetch={refetch}
        loading={loading}
      />
    </Card>
  )
}

export default ReportSourcesCard
