import { useMutation } from '@apollo/client'
import { changePassword } from '@faceup/crypto'
import { Button, Form, Input, QRCode, Typography, notification } from '@faceup/ui-base'
import { isEmail } from '@faceup/utils'
import OTP from 'otp'
import { useState } from 'react'
import { graphql } from '../__generated__'
import PageTemplateUnlogged from './PageTemplateUnlogged'

const { Title, Paragraph } = Typography

const generateTotpUri = (
  secret = '',
  accountName = '',
  issuer = '',
  algo = 'SHA1',
  digits = 6,
  period = 30
) =>
  `otpauth://totp/${encodeURI(issuer)}:${encodeURI(accountName)}?secret=${secret
    .replace(/[\s._-]+/g, '')
    .toUpperCase()}&issuer=${encodeURIComponent(
    issuer
  )}&algorithm=${algo}&digits=${digits}&period=${period}`

const mutations = {
  CreateAdminRequest: graphql(`
    mutation CreateAdminRequestMutation($input: CreateAdminRequestInput!) {
      createAdminRequest(input: $input) {
        success
      }
    }
  `),
}

const CreateAdmin = () => {
  const [name, setName] = useState({ value: '', error: false })
  const [email, setEmail] = useState({ value: '', error: false })
  const [password, setPassword] = useState({ value: '', error: false })
  const [passwordAgain, setPasswordAgain] = useState({ value: '', error: false })
  const [token, setToken] = useState({ value: '', error: false })
  const [secret, setSecret] = useState('')
  const [showFinished, setShowFinished] = useState(false)

  const [requestCreateAdmin, { loading }] = useMutation(mutations.CreateAdminRequest, {
    onError: e => {
      if (e.message === 'User has no permissions') {
        setToken({ ...token, error: true })
      } else {
        notification.error({
          message: 'Server error',
          description: e.message,
        })
      }
    },
    onCompleted: () => {
      setShowFinished(true)
    },
  })

  const submitForm = async () => {
    let hasError = false

    if (!name.value.trim()) {
      setName({ ...name, error: true })
      hasError = true
    }

    if (!email.value.trim() || !isEmail(email.value.trim())) {
      setEmail({ ...email, error: true })
      hasError = true
    }

    if (!password.value.trim()) {
      setPassword({ ...password, error: true })
      hasError = true
    }

    if (!passwordAgain.value.trim() || passwordAgain.value.trim() !== password.value.trim()) {
      setPasswordAgain({ ...passwordAgain, error: true })
      hasError = true
    }

    if (!token.value.trim()) {
      setToken({ ...token, error: true })
      hasError = true
    }

    if (hasError) {
      return
    }

    const payload = await changePassword(password.value, 'generate')
    if (payload.isErr()) {
      notification.error({ message: 'Encryption error', description: payload.error.message })
      return
    }

    const { passwordPrehash, passwordKey, ...input } = payload.value

    const otp = new OTP()
    const totpSecret = otp.secret
    setSecret(totpSecret)

    await requestCreateAdmin({
      variables: {
        input: {
          ...input,
          name: name.value.trim(),
          email: email.value.trim(),
          token: token.value,
          passwordPrehash,
          totpSecret,
        },
      },
    })
  }

  const issuer = 'FaceUp Kredenc'
  const totpUri = generateTotpUri(secret, email.value.trim(), issuer)

  if (showFinished) {
    return (
      <PageTemplateUnlogged isFooterShown>
        <Title>We will get in touch</Title>
        <Paragraph style={{ textAlign: 'center' }}>
          Just give us a <b>few days</b> until we review your account request. If we do not respond
          in reasonable time or you need your account quickly, write a message to{' '}
          <b>#dev-requests</b> channel on Slack.
          <br />
          <br />
          <b>🚨🚨🚨 Before you leave 🚨🚨🚨</b>, please set up two-factor authentication on your
          phone or you will not be able to login afterwards. Each time you want to login, you will
          need to enter your email, password and the code generated by the Authenticator app, which
          is changing every 60 seconds.
          <br />
          You can do this by scanning the QR code below with the Google Authenticator app. Available
          for{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
          >
            Android
          </a>{' '}
          and{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://apps.apple.com/us/app/google-authenticator/id388497605'
          >
            iOS
          </a>
          .
          <br />
          <br />
          <b>🚨🚨🚨 DO NOT SHARE THIS QR CODE WITH ANYONE. 🚨🚨🚨</b> It is like a password, you are
          not sharing it either.
        </Paragraph>
        <QRCode size={240} value={totpUri} bordered={false} />
        <br />
        <br />
        <Paragraph>
          In case the QR code does not show, you can enter manually the following code:{' '}
          <b>{secret}</b>
        </Paragraph>
      </PageTemplateUnlogged>
    )
  }

  return (
    <PageTemplateUnlogged isFooterShown>
      <Title>Hi 👋!</Title>
      <Paragraph style={{ textAlign: 'center' }}>
        This site will provide us with the info we need for creating your internal admin account.
        Note that this process is asynchronous so after submitting the form it will take us some
        time until we will let you inside the administration. <br />
        Pavel Ihm (CTO) will let you know via Slack when it&apos;s finished.
      </Paragraph>
      <Form layout='vertical'>
        <Form.Item
          label='Name'
          required
          {...(name.error && {
            validateStatus: 'error',
            help: 'Invalid name',
          })}
        >
          <Input
            type='name'
            autoComplete='name'
            placeholder='Your official full name'
            value={name.value}
            onChange={({ target: { value } }) => setName({ error: false, value })}
          />
        </Form.Item>
        <Form.Item
          label='Email'
          required
          {...(email.error && {
            validateStatus: 'error',
            help: 'Invalid email format',
          })}
        >
          <Input
            type='email'
            autoComplete='email'
            placeholder='Your FaceUp email address'
            value={email.value}
            onChange={({ target: { value } }) => setEmail({ error: false, value })}
          />
        </Form.Item>
        <Form.Item
          label='New password'
          required
          {...(password.error && {
            validateStatus: 'error',
            help: 'Password must be set',
          })}
        >
          <Input.Password
            autoComplete='new-password'
            value={password.value}
            onChange={({ target: { value } }) => setPassword({ error: false, value })}
          />
        </Form.Item>
        <Form.Item
          label='New password again (just in case you have misspelled something)'
          required
          {...(passwordAgain.error && {
            validateStatus: 'error',
            help: 'Passwords are not same',
          })}
        >
          <Input.Password
            autoComplete='new-password'
            value={passwordAgain.value}
            onChange={({ target: { value } }) => setPasswordAgain({ error: false, value })}
          />
        </Form.Item>
        <Form.Item
          label='Token you have obtained (for security)'
          required
          {...(!token.value && {
            help: 'If you do not have the token, ask in #dev-requests Slack channel for it :) ',
          })}
          {...(token.error && {
            validateStatus: 'error',
            help: 'Invalid token',
          })}
        >
          <Input
            autoComplete='off'
            value={token.value}
            onChange={({ target: { value } }) => setToken({ error: false, value })}
          />
        </Form.Item>
        <Button
          htmlType='submit'
          type='primary'
          block
          loading={loading}
          className='mt-16px'
          onClick={e => {
            e.preventDefault()
            submitForm()
          }}
        >
          I am ready for my FaceUp journey!
        </Button>
      </Form>
    </PageTemplateUnlogged>
  )
}

export default CreateAdmin
