import { Pagination as AntPagination, type PaginationProps as AntPaginationProps } from 'antd'
import { itemRender, showSizeChanger } from './helpers/paginationHelper'

export type PaginationProps = AntPaginationProps

export const Pagination = (props: PaginationProps) => {
  return (
    <AntPagination
      {...props}
      itemRender={props?.itemRender ?? itemRender}
      showSizeChanger={showSizeChanger}
    />
  )
}
