import { FuAdminRoleType } from '@faceup/utils'
import { type ReactNode, createContext, useContext } from 'react'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  FuViewerProvider_admin: graphql(`
    fragment FuViewerProvider_admin on FuAdmin {
      id
      name
      role
      companyCountries
      schoolCountries
    }
  `),
}

type AdminAccessRightsContextProps = {
  isAllowedInstitutionEdit: boolean
  isAllowedInstitutionLink: boolean
  isAllowedCreatePaymentUrl: boolean
  isAllowedPartnerApprove: boolean
  isAllowedPartnerDelete: boolean
  isAllowedIntrowActivation: boolean
  isAllowedDemoCompany: boolean
  isAllowedApproveOrRejectSchool: boolean
  isAllowedInstitutionMemberActions: boolean
  isAllowedBasicMemberActions: boolean
}

type AdminAccessRightsProps = {
  children: ReactNode
  viewer: FragmentType<typeof fragments.FuViewerProvider_admin> | null
}

export const AdminAccessRightsContext = createContext<AdminAccessRightsContextProps>({
  isAllowedInstitutionLink: false,
  isAllowedInstitutionEdit: false,
  isAllowedCreatePaymentUrl: false,
  isAllowedPartnerApprove: false,
  isAllowedPartnerDelete: false,
  isAllowedIntrowActivation: false,
  isAllowedDemoCompany: false,
  isAllowedApproveOrRejectSchool: false,
  isAllowedInstitutionMemberActions: false,
  isAllowedBasicMemberActions: false,
})

const AdminAccessRights = ({ children, viewer: _viewer }: AdminAccessRightsProps) => {
  const viewer = getFragmentData(fragments.FuViewerProvider_admin, _viewer)
  const isManager = viewer?.role === FuAdminRoleType.Manager
  const isAdmin = viewer?.role === FuAdminRoleType.Admin
  const isPsychologist = viewer?.role === FuAdminRoleType.Psychologist

  const permissions: AdminAccessRightsContextProps = {
    isAllowedInstitutionEdit: isAdmin,
    isAllowedPartnerApprove: isAdmin || isManager,
    isAllowedPartnerDelete: isAdmin,
    isAllowedIntrowActivation: isAdmin,
    isAllowedCreatePaymentUrl: isAdmin || isManager,
    isAllowedInstitutionLink: isAdmin || isManager,
    isAllowedDemoCompany: isAdmin || isManager,
    isAllowedApproveOrRejectSchool: isAdmin || isPsychologist,
    isAllowedInstitutionMemberActions: isAdmin,
    isAllowedBasicMemberActions: isManager,
  }

  return (
    <AdminAccessRightsContext.Provider value={permissions}>
      {children}
    </AdminAccessRightsContext.Provider>
  )
}

export const useAdminAccessRights = () => useContext(AdminAccessRightsContext)

export default AdminAccessRights
