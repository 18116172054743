import { useMutation, useQuery } from '@apollo/client'
import { Card, UserAvatar } from '@faceup/ui'
import { Space, Table, Tag, Typography, notification } from '@faceup/ui-base'
import { Switch } from '@faceup/ui-base'
import { InvitationStatus } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { useAdminAccessRights } from '../Contexts/AdminAccessRightsProvider'
import { graphql } from '../__generated__'
import { DATE_FORMAT } from '../constants'

const { Text } = Typography

const query = {
  PartnerMembersQuery: graphql(`
    query PartnerMembersQuery($partnerId: PartnerInstitutionGlobalId!) {
      partner(partnerId: $partnerId) {
        id
        members {
          id
          name
          email
          phone
          profileImageUrl
          invitation
          lastLogin
          isIntrowEnabled
        }
      }
    }
  `),
}

const mutation = {
  ToggleIntrowEnabledMutation: graphql(`
    mutation ToggleIntrowEnabledMutation($input: ToggleIntrowEnabledInput!) {
      toggleIntrowEnabled(input: $input) {
        member {
          id
          isIntrowEnabled
        }
      }
    }
  `),
}

type Props = {
  institutionId: string | null
}

const PartnersTable = ({ institutionId }: Props) => {
  const { data, loading } = useQuery(query.PartnerMembersQuery, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    fetchPolicy: 'cache-and-network',
    variables: {
      partnerId: institutionId ?? '',
    },
  })

  const institution = data?.partner
  const members = institution?.members ?? []

  return (
    <Card>
      <Card.Header>Members</Card.Header>
      <Table
        loading={loading}
        rowKey='id'
        dataSource={members}
        locale={{ emptyText: ' ' }}
        scroll={{ x: 'max-content' }}
        columns={[
          {
            title: 'Name',
            key: 'name',
            width: '60%',
            render: member => (
              <Space align='center'>
                <UserAvatar user={member} style={{ marginRight: 10 }} />
                <Space direction='vertical' size={0}>
                  <Text style={{ fontSize: 14 }}>{member.name || member.email}</Text>
                  <Text type='secondary' style={{ fontSize: 13 }}>
                    {member.email} {member.phone && <>({member.phone})</>}
                  </Text>
                </Space>
              </Space>
            ),
          },
          {
            title: 'Status',
            key: 'invitation',
            width: '10%',
            render: ({ invitation }) => (
              <>
                {invitation === InvitationStatus.Active && <Tag color='#217F50'>Active</Tag>}
                {invitation === InvitationStatus.Expired && <Tag color='#B60024'>Expired</Tag>}
                {invitation === InvitationStatus.Sent && <Tag color='#E66B00'>Sent</Tag>}
              </>
            ),
          },
          {
            title: 'Introw',
            key: 'isIntrowEnabled',
            width: '10%',
            render: member => <IntrowEnabledSwitch member={member} />,
          },
          {
            title: 'Last login',
            key: 'lastLogin',
            width: '20%',
            render: ({ lastLogin }) => (lastLogin ? moment(lastLogin).format(DATE_FORMAT) : ''),
          },
        ]}
        pagination={false}
      />
    </Card>
  )
}

type IntrowEnabledSwitchProps = {
  member: NonNullable<
    NonNullable<ResultOf<typeof query.PartnerMembersQuery>['partner']>['members']
  >[number]
}

const IntrowEnabledSwitch = ({ member }: IntrowEnabledSwitchProps) => {
  const { isAllowedIntrowActivation } = useAdminAccessRights()

  const [toggleIntrowEnabled, { loading: toggleIntrowEnabledLoading }] = useMutation(
    mutation.ToggleIntrowEnabledMutation,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
    }
  )

  return (
    <Switch
      checked={member.isIntrowEnabled}
      disabled={!isAllowedIntrowActivation}
      loading={toggleIntrowEnabledLoading}
      onChange={() =>
        toggleIntrowEnabled({
          variables: { input: { memberId: member.id, isEnabled: !member.isIntrowEnabled } },
        })
      }
    />
  )
}

export default PartnersTable
