import styled from '@emotion/styled'
import { Card } from '@faceup/ui'
import { Layout } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import PublicHeader from '../Components/PublicHeader'
import Footer from './Login/Footer'

const { Content: AntContent } = Layout

type PageTemplateUnloggedProps = {
  children?: ReactNode
  contentUnderCard?: ReactNode
  isFooterShown?: boolean
}

const PageTemplateUnlogged = ({
  children,
  contentUnderCard,
  isFooterShown = false,
}: PageTemplateUnloggedProps) => (
  <LayoutFullHeight>
    <PublicHeader />
    <Content>
      <Card className='max-w-[35rem] w-full'>
        <div className='flex flex-col nowrap items-center py-[3.5rem] px-[24px]'>{children}</div>
      </Card>
      {contentUnderCard}
    </Content>
    {isFooterShown && <Footer />}
  </LayoutFullHeight>
)

const LayoutFullHeight = styled(Layout)`
  min-height: 100%;
`
const Content = styled(AntContent)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-bottom: 2em;
`

export default PageTemplateUnlogged
