import { Button } from '@faceup/ui-base'
import { useState } from 'react'
import LinkCompanyToPartnerModal from './LinkCompanyToPartnerModal'

type Props = {
  motherId: string
  partnerId?: string
}

const LinkCompanyToPartner = ({ partnerId, motherId }: Props) => {
  const [isPartnerLinkModalVisible, setIsPartnerLinkModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <Button type='primary' onClick={() => setIsPartnerLinkModalVisible(true)} loading={isLoading}>
        Link to partner
      </Button>

      <LinkCompanyToPartnerModal
        setIsLoading={setIsLoading}
        partnerId={partnerId}
        motherId={motherId}
        visible={isPartnerLinkModalVisible}
        hide={() => setIsPartnerLinkModalVisible(false)}
      />
    </>
  )
}

export default LinkCompanyToPartner
