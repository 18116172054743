import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { ulUpload01 } from '@faceup/icons/ulUpload01'
import {
  Button,
  Form,
  Input,
  ModalConfirm,
  MultiSelect,
  Select,
  Space,
  Upload,
} from '@faceup/ui-base'
import {
  ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP,
  Country,
  DEFAULT_LANGUAGE,
  Institution,
  type Language,
  convertCountryToCountryName,
  isEmail,
  isUrl,
} from '@faceup/utils'
import { useState } from 'react'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import ServiceTranslationsTab from './ServiceTranslationsTab'

const fragments = {
  CompanyServiceEditor_companyService: graphql(`
    fragment CompanyServiceEditor_companyService on CompanyService {
      id
      providerName
      providerEmail
      providerUrl
      countries
      logoUrl
      defaultLanguage
      institutionType

      notAvailablePartners {
        id
        name
      }

      allTranslations {
        id
        language
        description
        categories
      }
    }
  `),
  CompanyServiceEditor_partner: graphql(`
    fragment CompanyServiceEditor_partner on PartnerInstitution {
      id
      name
    }
  `),
}

type Props = {
  loading: boolean
  service: FragmentType<typeof fragments.CompanyServiceEditor_companyService> | null
  saveChanges: (service: {
    providerName: string
    providerEmail: string
    providerUrl: string
    countries: Country[]
    institutionType: Institution
    logo: File | null
    defaultLanguage: Language
    notAvailablePartnerIds: string[]
    translations: {
      language: Language
      description: string
      categories: string[]
    }[]
  }) => void
  visible: boolean
  setVisible: (visible: boolean) => void
  partners: FragmentType<typeof fragments.CompanyServiceEditor_partner>[]
}

const CompanyServiceEditor = ({ service: _service, partners: _partners, ...props }: Props) => {
  const service = getFragmentData(fragments.CompanyServiceEditor_companyService, _service)
  const partners = getFragmentData(fragments.CompanyServiceEditor_partner, _partners)

  const [providerLogo, setProviderLogo] = useState<{
    value: File | null
    url: string
    error: boolean
  }>({
    value: null,
    url: service?.logoUrl ?? '',
    error: false,
  })
  const [providerNameField, setProviderNameField] = useState({
    value: service?.providerName ?? '',
    error: false,
  })
  const [providerEmailField, setProviderEmailField] = useState({
    value: service?.providerEmail ?? '',
    error: false,
  })
  const [providerUrlField, setProviderUrlField] = useState({
    value: service?.providerUrl ?? '',
    error: false,
  })
  const [countriesField, setCountriesField] = useState({
    value: service?.countries ?? [],
    error: false,
  })
  const [notAvailablePartnerIdsField, setNotAvailablePartnerIdsField] = useState({
    value: service?.notAvailablePartners?.map(partner => partner?.id ?? '') ?? [],
    error: false,
  })
  const [institutionType, setInstitutionType] = useState({
    value: service?.institutionType ?? Institution.Company,
    error: false,
  })

  const [translations, setTranslations] = useState(
    service?.allTranslations
      ?.filter(t => t?.language ?? false)
      .map(translation => ({
        // biome-ignore lint/style/noNonNullAssertion:
        language: translation!.language!,
        description: {
          error: false,
          value: translation?.description ?? '',
        },
        categories: translation?.categories ?? [],
      })) ?? [
      {
        language: DEFAULT_LANGUAGE,
        description: {
          error: false,
          value: '',
        },
        categories: [],
      },
    ]
  )

  const [defaultLanguage, setDefaultLanguage] = useState(
    service?.defaultLanguage ?? DEFAULT_LANGUAGE
  )

  const validateForm = () => {
    let ok = true

    if (!service && !providerLogo.value) {
      setProviderLogo({ ...providerLogo, error: true })
      ok = false
    }

    if (!providerNameField.value) {
      setProviderNameField({ ...providerNameField, error: true })
      ok = false
    }
    if (!isEmail(providerEmailField.value)) {
      setProviderEmailField({ ...providerEmailField, error: true })
      ok = false
    }
    if (!isUrl(providerUrlField.value)) {
      setProviderUrlField({ ...providerUrlField, error: true })
      ok = false
    }
    if (!countriesField.value.length) {
      setCountriesField({ ...countriesField, error: true })
      ok = false
    }

    if (!institutionType.value) {
      setInstitutionType({ ...institutionType, error: true })
      ok = false
    }

    const tempTranslations = translations
    for (const key in Object.keys(tempTranslations)) {
      const translations = tempTranslations[key]

      if (!translations?.description?.value) {
        ok = false
        // @ts-expect-error Migrating to strict mode, fix this
        tempTranslations[key].description = {
          ...translations?.description,
          error: true,
        }
      }
    }

    setTranslations(tempTranslations)
    return ok
  }

  return (
    <ModalConfirm
      title={service ? 'Edit' : 'Add'}
      open={props.visible}
      width={800}
      onConfirm={() => {
        if (!validateForm()) {
          return
        }
        props.saveChanges({
          providerName: providerNameField.value.trim(),
          providerEmail: providerEmailField.value.trim(),
          providerUrl: providerUrlField.value.trim(),
          countries: countriesField.value,
          institutionType: institutionType.value,
          logo: providerLogo.value,
          defaultLanguage,
          notAvailablePartnerIds: notAvailablePartnerIdsField.value,
          translations: translations.map(translation => ({
            // biome-ignore lint/style/noNonNullAssertion:
            language: translation.language!,
            // biome-ignore lint/style/noNonNullAssertion:
            description: translation.description.value!.trim(),
            categories: translation.categories.filter(c => c),
          })),
        })
      }}
      onClose={() => props.setVisible(false)}
      onCancel={() => props.setVisible(false)}
      confirmState={{
        loading: props.loading,
      }}
    >
      <Form layout='vertical'>
        <Form.Item
          label='Name'
          {...(providerNameField.error && {
            validateStatus: 'error',
            help: 'Invalid name',
          })}
        >
          <Input
            autoComplete='organization'
            value={providerNameField.value}
            onChange={({ target: { value } }) => {
              setProviderNameField({ error: false, value })
            }}
          />
        </Form.Item>
        <Form.Item
          label='Logo'
          {...(providerLogo.error && {
            validateStatus: 'error',
            help: 'Invalid logo',
          })}
        >
          <Space>
            {(providerLogo.url ?? service?.logoUrl) && (
              <ServiceLogo src={providerLogo.url ?? service?.logoUrl} />
            )}
            <Upload
              accept={Object.keys(ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP).join(',')}
              beforeUpload={(file: File) => {
                setProviderLogo({ value: file, url: URL.createObjectURL(file), error: false })
                return false
              }}
              showUploadList={false}
            >
              <Button ghost type='primary'>
                <Space>
                  <UntitledIcon icon={ulUpload01} />
                  Upload logo
                </Space>
              </Button>
            </Upload>
          </Space>
        </Form.Item>
        <Form.Item
          label='E-mail'
          {...(providerEmailField.error && {
            validateStatus: 'error',
            help: 'Invalid e-mail',
          })}
        >
          <Input
            autoComplete='email'
            type='email'
            value={providerEmailField.value}
            onChange={({ target: { value } }) => {
              setProviderEmailField({ error: false, value })
            }}
          />
        </Form.Item>
        <Form.Item
          label='Provider URL'
          {...(providerUrlField.error && {
            validateStatus: 'error',
            help: 'Invalid URL',
          })}
        >
          <Input
            autoComplete='off'
            value={providerUrlField.value}
            onChange={({ target: { value } }) => {
              setProviderUrlField({ error: false, value })
            }}
          />
        </Form.Item>
        <Form.Item
          label='Institution'
          {...(institutionType.error && {
            validateStatus: 'error',
            help: 'Invalid institution',
          })}
        >
          <Select<Institution>
            value={institutionType.value}
            onChange={value => {
              setInstitutionType({ error: false, value })
            }}
            options={Object.values(Institution).map(institution => ({
              label: institution,
              value: institution,
            }))}
          />
        </Form.Item>
        <Form.Item
          label='Country'
          {...(countriesField.error && {
            validateStatus: 'error',
            help: 'Invalid country',
          })}
        >
          <MultiSelect
            value={countriesField.value}
            onChange={value => {
              setCountriesField({ error: false, value })
            }}
            options={Object.values(Country).map(country => ({
              label: convertCountryToCountryName(country, 'en'),
              value: country,
            }))}
          />
        </Form.Item>

        <Form.Item label='Not available partners'>
          <MultiSelect
            placeholder='Select partners which will not see this service'
            style={{ width: '100%' }}
            value={notAvailablePartnerIdsField.value}
            onChange={value => {
              setNotAvailablePartnerIdsField({ error: false, value })
            }}
            options={partners.map(partner => ({
              label: partner.name,
              value: partner.id,
            }))}
          />
        </Form.Item>

        <ServiceTranslationsTab
          translations={translations}
          defaultLanguage={defaultLanguage}
          setTranslations={setTranslations}
          setDefaultLanguage={setDefaultLanguage}
        />
      </Form>
    </ModalConfirm>
  )
}

const ServiceLogo = styled.div<{ src: string }>`
  height: 50px;
  width: 100px;
  background: url('${({ src }) => src}') center left no-repeat;
  background-size: contain;
`

export default CompanyServiceEditor
