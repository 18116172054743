import { Table } from '@faceup/ui-base'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  ReportSourcesTable_reportSource: graphql(`
    fragment ReportSourcesTable_reportSource on ReportSource {
      id
      sourceType
      shortLink
      hasActivePassword
      reportPassword(motherId: $motherId)
    }
  `),
}

type OtherReportSourceType = ResultOf<typeof fragments.ReportSourcesTable_reportSource>

type Props = {
  reportSources: FragmentType<typeof fragments.ReportSourcesTable_reportSource>[]
  loading: boolean
}

const ReportSourcesTable = ({ reportSources: _reportSources, loading }: Props) => {
  const reportSources = getFragmentData(fragments.ReportSourcesTable_reportSource, _reportSources)
  const makeTableRow = (reportSource: OtherReportSourceType) => ({
    id: reportSource.id,
    password: reportSource.reportPassword,
    type: reportSource.sourceType,
    shortLink: reportSource.shortLink,
    hasActivePassword: reportSource.hasActivePassword,
  })

  return (
    <Table<ReturnType<typeof makeTableRow>>
      style={{ marginTop: 20 }}
      loading={loading}
      rowKey='id'
      dataSource={reportSources.map(reportSource => makeTableRow(reportSource))}
      locale={{ emptyText: ' ' }}
      scroll={{ x: 'max-content' }}
      columns={[
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Has active password',
          key: 'hasActivePassword',
          render: ({ hasActivePassword }) => <>{hasActivePassword ? 'Yes' : 'No'}</>,
        },
        {
          title: 'Password',
          dataIndex: 'password',
          key: 'password',
        },
        {
          title: 'Short link',
          key: 'shortLink',
          render: ({ shortLink }) => (
            <>
              <a
                target='_blank'
                href={`https://www.faceup.com/c/${shortLink}`}
                rel='noreferrer noopener'
              >
                https://faceup.com/c/{shortLink}
              </a>
              <br />
              <a
                target='_blank'
                href={`https://www.nntb.cz/c/${shortLink}`}
                rel='noreferrer noopener'
              >
                https://nntb.cz/c/{shortLink}
              </a>
            </>
          ),
        },
      ]}
      pagination={false}
    />
  )
}

export default ReportSourcesTable
